import { SubpageLayout } from '../components/SubpageLayout';
import { Seo } from "../components/Seo";
import * as React from 'react';
import styled from 'styled-components';

const benefitData = [
  {
    id: '1',
    content: 'Praca zdalna (1 dzień na 2 tygodnie pracujemy z biura w Gdańsku)',
  },
  {
    id: '2',
    content: 'W niektórych przypadkach możliwa praca w pełni zdalna',
  },
  {
    id: '3',
    content: 'Realny wpływ na technologie, których używamy',
  },
  {
    id: '4',
    content: 'Kontakt z nowymi technologiami i rozwiązaniami',
  },
  {
    id: '5',
    content: '2-tygodniowe sprinty',
  },
  {
    id: '6',
    content: 'Elastyczne godziny pracy',
  },
  {
    id: '7',
    content: 'Płaska struktura zespołu',
  },
  {
    id: '8',
    content: 'Otwarte i wspierające środowisko pracy',
  },
  {
    id: '9',
    content: 'Dedykowany budżet na rozwój',
  },
  {
    id: '10',
    content: 'Dni rozwojowe do wykorzystania w godzinach pracy',
  },
  {
    id: '11',
    content: 'Integracje zespołowe oraz firmowe',
  },
  {
    id: '12',
    content: 'Dofinansowanie karty Multisport',
  },
  {
    id: '13',
    content: 'Pakiet ochrony zdrowia oraz dostępne preferencyjne ubezpieczenie na życie',
  },
  {
    id: '14',
    content: 'Umowa o pracę lub B2B',
  },
  {
    id: '15',
    content: 'Nasze biuro mieści się w nowoczesnym budynku zlokalizowanym w centrum Gdańska - Tryton Business House – bardzo blisko dworca kolejowego Gdańsk Główny',
  },
];

const jobTitle = [
  {
    id: '1',
    content: 'Full Stack JavaScript Developer',
    level: 'Mid / Senior',
    link: 'jobTitle0',
  },
];

const recruitmentSteps = [
  {
    id: '1',
    content: 'Pierwszy kontakt z działem HR / zarządem.',
  },
  {
    id: '2',
    content: 'Przesłanie zadania rekrutacyjnego. Będzie ono polegało na przygotowaniu wykonywalnego kodu / prostej aplikacji.',
  },
  {
    id: '3',
    content: 'Czekamy 7 dni na otrzymanie od Ciebie gotowego zadania rekrutacyjnego.',
  },
  {
    id: '4',
    content: 'Po weryfikacji zadania, wybrane osoby zapraszamy na spotkanie techniczne z członkami zespołu.',
  },
  {
    id: '5',
    content: 'Rozpoczynamy współpracę po pomyślnie przebytej rekrutacji.',
  },
];

const requirementsData = [
  {
    id: '1',
    content: 'Minimum 4 lata doświadczenia w pracy z React + TypeScript (komercyjne) - warunek konieczny.',
  },
  {
    id: '2',
    content: 'Minimum 2 lata doświadczenia w pracy z Node + TypeScript (komercyjne) - warunek konieczny.',
  },
  {
    id: '3',
    content: 'Bardzo dobra znajomość HTML5, CSS3 oraz JavaScript (ES6+).',
  },
  {
    id: '4',
    content: 'Doświadczenie w implementowaniu responsywnych interfejsów użytkownika.',
  },
  {
    id: '5',
    content: 'Umiejętność pisania testów jednostkowych i integracyjnych.',
  },
  {
    id: '6',
    content: 'Znajomość systemu kontroli wersji GIT.',
  },
  {
    id: '7',
    content: 'Bycie na bieżąco z nowymi technologiami internetowymi i stosowaniem nowych technologii do przyszłych rozwiązań.',
  },
  {
    id: '8',
    content: 'SOLIDne praktyki kodowania.',
  },
  {
    id: '9',
    content: 'Polish native - praca w języku polskim wymagająca zrozumienia zagadnień biznesowych.',
  },
];

const JobItem = styled.div`
  > :not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const ApplicationTriger = styled.a`
  display: flex;
  justify-content: space-around;
`;

const JobOffer = () => (
  <div className="container">
    <div className="flex flex-col flex-wrap">
      {jobTitle.map((item) => {
        const anchor = `#${item.link}`;

        return (
          <div
            key={item.id}
            className="px3"
          >
            <a
              className="flex flex-no-wrap flex-row px-3 my-3 content-color cursor-pointer justify-between"
              href={anchor}
            >
              <div className="pr-8 flex">
                <i
                  className="fa fa-link pr-4 self-center"
                  aria-hidden="true"
                />
                <p className=" pr-5 text-2xl self-center">
                  {item.content}
                </p>
              </div>
              <p className="flex self-center">{item.level}</p>
            </a>
          </div>
        );
      })}
      <p className="content-color pt-5 pl-10">
        Nie znalazłeś stanowiska, które odpowiada Twoim umiejętnościom? <br/>
        Opisz swoje doświadczenie i wyślij nam swoje CV.
      </p>
      <ApplicationTriger
        className="mx-auto w-48 hover:underline bg-red-600 font-bold rounded-full my-10 py-4 px-8 shadow-lg text-white uppercase"
        href="mailto:rekrutacja.info@fakturuj.to ?subject=Aplikacja"
      >
        Aplikuj
      </ApplicationTriger>
    </div>
  </div>
);

const BenefitOffer = () => (
  <div className="container">
    <div className="flex flex-row flex-wrap">
      {benefitData.map((item) => (
        <div
          key={item.id}
          className="px-3 flex flex-col w-full md:w-1/2 lg:w-1/3 h-32 lg:h-24 mx-auto"
        >
          <div>
            <p className="text-lg pb-4 flex flex-1 items-center justify-center">
              <i
                className="fa fa-asterisk content-color"
                aria-hidden="true"
              />
            </p>
          </div>
          <div className="content-color">{item.content}</div>
        </div>
      ))}
    </div>
  </div>
);

const RecruitmentProcess = () => (
  <ol className="container content-color list-decimal pl-8">
    {recruitmentSteps.map((item) => (
      <li
        key={item.id}
      >
        {item.content}
      </li>
    ))}
  </ol>
);

const Requirements = () => (
  <ol className="container list-disc pl-4 pb-4">
    {requirementsData.map((item) => (
      <li
        key={item.id}
      >
        {item.content}
      </li>
    ))}
  </ol>
);

const Career = () => (
  <SubpageLayout
    title="Kariera"
  >
    <Seo
      title="Kariera"
      description="Podstrona poświęcona treści rekrutacyjnej"
      keywords={[ 'kariera', 'praca', 'oferty zatrudnienia' ]}
    />
    <div>
      <section className="container flex px-2 pb-16 lg:pb-20">
        <div className="flex flex-col lg:flex-row lg:justify-around lg:w-full ">
          <h3 className="flex uppercase text-3xl pb-4 lg:pb-0 font-extralight ">
            Sprawdź na jakie stanowiska obecnie rekrutujemy
          </h3>
        </div>
      </section>
      <section className="container pb-16">
        <h3
          className="text-center mb-12 font-semibold"
          id={'jobOffer'}
        >
          Aktualne oferty pracy
        </h3>
        <JobOffer/>
      </section>
      <section className="container pb-16">
        <h3 className="text-center mb-12 font-semibold">Co oferujemy</h3>
        <BenefitOffer/>
      </section>
      <section className="container pb-16">
        <h3 className="text-center mb-12 font-semibold mt-4 sm:mt-0 md:mt-10 lg:mt-16 xl:mt-10">Proces rekrutacji</h3>
        <RecruitmentProcess/>
      </section>
      <section className="container pb-16">
        <h3 className="text-center mb-12 font-semibold">Detale ogłoszeń</h3>
        <JobItem
          className="container content-color pb-10 pl-5"
          id={'jobTitle0'}
        >
          <div className="flex flex-row flex-no-wrap justify-between pb-5">
            <p className="pr-5 text-2xl self-center">{jobTitle[0].content}</p>
            <p className="flex self-center">{jobTitle[0].level}</p>
          </div>
          <div>
            <div className="flex flex-row pb-2">
              <p className="font-bold">Wynagrodzenie:</p>
              <p className="font-bold pl-2">9 - 16 tys. zł</p>
            </div>
            <div className="flex flex-row flex-wrap">
              <p>
                Zajmujemy się rozwijaniem aplikacji webowej&nbsp;
              </p>
              <a
                href="/filmy-wprowadzajace"
                target="_blank"
              >
              <span
                style={{ color: "#d8232a" }}
                className="font-bold"
              >
                FAKTURUJ.
              </span>
                <span
                  style={{ color: "#283278" }}
                  className="font-bold"
                >
                TO
              </span>
              </a>
            </div>
            <p className="pb-4">
              Prace prowadzi zespół programistów wspierany ekspertami z zakresu księgowości.
              W czasie pracy nad aplikacją będziesz mógł rozwijać swoje umiejętności zarówno po stronie front-end'u jak
              i back-end'u. W miarę potrzeby piszemy własne narzędzia, które ułatwiają development.
            </p>
          </div>
          <p className="font-bold">Wymagania:</p>
          <Requirements/>
          <p className="font-bold">Dodatkowe atuty</p>
          <ol className="list-disc pl-4 pb-4">
            <li>Doświadczenie w pracy z
              <ol className="list-disc pl-4">
                <li>Azure</li>
                <li>MongoDB</li>
                <li>Redux</li>
                <li>Gatsby</li>
                <li>Docker</li>
                <li>Bitbucket Pipelines</li>
              </ol>
            </li>
            <li>Umiejętność pisania testów E2E</li>
            <li>Dobra znajomość zagadnień związanych z protokołem HTTP oraz interfejsami REST</li>
            <li>Doświadczenie w pracy ze Scrum</li>
            <li>Angielski na poziomie potrzebnym do pracy z dokumentacją</li>
            <li>Zamiłowanie do tworzenia “eleganckiego kodu” :)</li>
          </ol>
          <a
            className="flex justify-center"
            href="#jobOffer"
          >
            <i
              className="fa fa-arrow-circle-up content-color text-3xl cursor-pointer"
              aria-label="Powrót do listy stanowisk"
            />
          </a>
        </JobItem>
      </section>
    </div>
  </SubpageLayout>
);

export default Career;
